@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400;600&display=swap);
/* Fonts */

.ewFont {
    font-family: "Raleway", sans-serif;
    font-weight: 400;
}

.ewFontBold {
    font-family: "Raleway", sans-serif;
    font-weight: 600;
}

html, body, #root {
    height: 100%;
    overflow-x: hidden;
}

.main-container {
    max-width: 100%;
    width: 400px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.custom-card {
    background-color: rgba(255,255,255,0.85) !important;  
}

.address-container {   
    margin-bottom: 12px;
}

.contact-container {
    margin-bottom: 12px;
    width: 100%;
}

.scrollable, .inner-scroll {
    overflow-y: scroll;
    overflow-x: hidden;
}

.scrollable {
    width: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollable::-webkit-scrollbar, .inner-scroll::-webkit-scrollbar, .outer-scroll::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollable, .inner-scroll, .outer-scroll {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.accountPanel {    
    display: inline-block;
    width:600px;
    margin:3px;
}

.fullHeight {
    height: 100%;
    max-height: 100%;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0px;
}

#blooblar {
    overflow-y: scroll;
}

:disabled {
    cursor: not-allowed;
}

.modal650 {
    width:650px;
}

.carousel {
    background-color: rgba(119,136,153, 0.5);
    border-radius: 5px;
    margin-bottom: 15px;
}

.carousel-caption {
    position: relative;
    top: 0;
    left: 0;
    margin-bottom: 15px;
}

.carousel-caption a {
    color: white;
}

.carousel-caption a:hover {
    text-decoration: underline;
    cursor: pointer;
}

/*.modal-header {
    background-color: #345391;
    color: white;
}*/

.modal-dialog-centered {
    margin: 0 auto !important;
}

.modal-content .close {
    color: white;
}

.delete-modal .close {
    color: black;
}

.delete-modal .modal-dialog {
    width: 300px;
}

.delete-modal .modal-content {
    background-color: rgba(52,83,145, 0.8) !important;
}

.delete-modal .modal-header {
    color: white !important;
}

/*.delete-modal .modal-header {
    background-color: rgba(255,255,255,0.4) !important;
}

.delete-modal .modal-title {
    background-color: rgba(255,255,255,0.4) !important;
}*/

.about-me-button {
    font-size: 1.1em;
    border: none !important;
    background-color: transparent;
    padding: 3px;
}

.about-me-button:visited {
    background-color: transparent !important;
    outline: none !important;
}

.about-me-button:hover {
    background-color: transparent;
    text-decoration: underline;
}

.about-me-button:active {
    background-color: transparent !important;
    box-shadow: none !important;
    outline: none !important;
}

.about-me-button:focus {
    background-color: transparent !important;
    box-shadow: none !important;
    outline: 3px solid rgba(255, 255, 255, 0.3);
}

.experience-button {
    z-index: 1;
}
.experience-button.active {
    outline: 5px solid rgba(255, 255, 255, 0.3);
    z-index: 2 !important;
}

/*Customize checkbox label (the container)*/
.check-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.check-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border-radius: 100%;
    border: 3px solid white;
    background-color: none;
}

/* On mouse-over, add a grey background color */
/*.check-container:hover input ~ .checkmark {
    background-color: #ccc;
}*/

/* When the checkbox is checked, increase size, add blue border */
.check-container input:checked ~ .checkmark {
    border-color: #6788ED;
    height: 35px;
    width: 35px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.check-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.check-container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 10px;
    height: 20px;
    border: solid #6788ED;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.delete-button {
    /*background-color: #345391;*/
    color: #345391 !important;
    background-color: rgba(255, 255, 255, 0) !important;
    border: none !important;
    padding-top: 0px;
    padding-bottom: 0px;
}

.delete-button:visited {
    color: #345391;
    background-color:  rgba(255, 255, 255, 0);
    box-shadow: none !important;
    outline: 3px solid rgba(52,83,145, 0.3);
}

.delete-button:hover {
    color: #345391;
    outline: 3px solid rgba(52,83,145, 0.3);
    background-color: rgba(255, 255, 255, 0);
}

.delete-button:active, .delete-button:focus {
    color: #345391 !important;
    background-color: rgba(255, 255, 255, 0) !important;
    box-shadow: none !important;
    outline: 3px solid rgba(52,83,145, 0.3) !important;
}

.slideLeft {
    -webkit-animation: slide-left 3s;
            animation: slide-left 3s;
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
    opacity: 0;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

@-webkit-keyframes slide-left {
    from {
        margin-left: 200px;
        margin-right: -200px;
        opacity: 0;
    }
    to {
        margin-left: 0;
        margin-right: 0;
        opacity: 1;     
    }
}

@keyframes slide-left {
    from {
        margin-left: 200px;
        margin-right: -200px;
        opacity: 0;
    }
    to {
        margin-left: 0;
        margin-right: 0;
        opacity: 1;     
    }
}

.appear {
    -webkit-animation: appear 2s;
            animation: appear 2s;
    -webkit-animation-delay: 5s;
            animation-delay: 5s;
    opacity: 0;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    margin-top:20px;
}

@-webkit-keyframes appear {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes appear {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/*Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {

    .main-container {
        width: 400px;
    }

    .address-search-results {
        width: 300px !important;
    }

    .icon-and-text {
        flex-direction: row !important;
    }

    .text-with-icon {
        padding-right: 2px;
    }

    .icon-with-text {
        padding-left: 2px;
        padding-right: 5px;
    }

    .el-team-text {
        font-size: 0.9em !important;
    }
    .el-team-heading {
        font-size: 1.7em !important;
    }
    .carousel {
        width: 300px !important;
    }
    .carousel-item {
        width: 300px !important;
    }
    .carousel img {
        height: 120px !important;
    }
    .carousel h4 {
        font-size: 1.2em;
        margin-bottom: 0px;
    }
    .carousel div {
        font-size: 0.96em;
        padding: 1px !important;
    }
    .carousel-caption {
        z-index: 1;
    }
    .carousel-control-prev, .carousel-control-next {
        z-index: 2;
    }
    .el-about-me {
        margin-bottom: 10px;
    }
}

/*Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {
    
    .welcome-banner {
        flex-direction: column;
    }
    .main-container {
        justify-content: flex-start;
    }
    .outer-scroll {
        overflow-y: scroll;
        overflow-x: hidden;
    }
    .inner-scroll {
        overflow-y: visible;
        overflow-x: visible;
    }
}
